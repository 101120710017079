import React from 'react';
import { graphql } from 'gatsby';

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const markdownRemark = data.allMarkdownRemark;
  const frontmatter = markdownRemark.nodes[0].frontmatter;
  const html = markdownRemark.nodes[0].html;
  return (
    <div className="blog-post-container">
      <div className="blog-post">
        <h1>{frontmatter.title}</h1>
        <h2>{frontmatter.date}</h2>
        <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    </div>
  );
}

export const pageQuery = graphql`
  query($path: String!) {
    allMarkdownRemark(filter: { frontmatter: { path: { eq: $path } } }) {
      nodes {
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          path
          title
        }
        html
      }
    }
  }
`;
